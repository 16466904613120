import '../../App.scss';
import React from 'react';
import Typewriter from 'typewriter-effect';
import "animate.css/animate.min.css";

import SVGGardian from '../Components/SVGGardian';
import VillaVideo from '../Elements/video/villavideo.mp4';
import saladelle1 from '../Elements/images/chambres/saladelle/saladelle1.jpg';
import saladelle3 from '../Elements/images/chambres/saladelle/saladelle3.jpg';
import saladelle4 from '../Elements/images/chambres/saladelle/saladelle4.jpg';
import saladelle5 from '../Elements/images/chambres/saladelle/saladelle5.jpg';
import asphodele1 from '../Elements/images/chambres/asphodele/asphodele1.png';
import asphodele2 from '../Elements/images/chambres/asphodele/asphodele2.png';
import asphodele3 from '../Elements/images/chambres/asphodele/asphodele3.png';
import asphodele4 from '../Elements/images/chambres/asphodele/asphodele4.png';
import jonquille1 from '../Elements/images/chambres/jonquille/jonquille1.jpg';
import jonquille2 from '../Elements/images/chambres/jonquille/jonquille2.jpg';
import jonquille3 from '../Elements/images/chambres/jonquille/jonquille4.jpg';
import jonquille4 from '../Elements/images/chambres/jonquille/jonquille3.jpg';
import pleinpied from '../Elements/icons/chambres/pleinpied.png';
import jardin from '../Elements/icons/chambres/jardin.png';
import wifi from '../Elements/icons/chambres/wifi.png';
import linge from '../Elements/icons/chambres/linge.png';
import animaux from '../Elements/icons/chambres/icon-animaux.png';
import clim from '../Elements/icons/chambres/icon-clim.png';
import doucheprive from '../Elements/icons/chambres/icon-douche-privee.png';
import four from '../Elements/icons/chambres/icon-four.png';
import lavelinge from '../Elements/icons/chambres/icon-lavelinge.png';
import lavevaisselle from '../Elements/icons/chambres/icon-lavevaisselle.png';
import menage from '../Elements/icons/chambres/icon-menage.png';
import microondes from '../Elements/icons/chambres/icon-microondes.png';
import parking from '../Elements/icons/chambres/icon-parking.png';
import plaques from '../Elements/icons/chambres/icon-plaques.png';
import chambres from '../Elements/icons/chambres/chambre.png';
import salon from '../Elements/icons/chambres/salon.png';
import nb from '../Elements/icons/chambres/nb.png';
import fermee from '../Elements/icons/chambres/cuisine-fermee.png';
import ouverte from '../Elements/icons/chambres/cuisine-ouverte.png'
import canape from '../Elements/icons/chambres/canape.png';
import simple from '../Elements/icons/chambres/simple.png';
import double from '../Elements/icons/chambres/double.png';
import sdb from '../Elements/icons/chambres/sdb.png';
import maison from '../Elements/icons/chambres/maison.png';
import FormGi from '../Components/ContactUs.js';
import Footer from '../Components/Footer.js';
import FooterFixed from '../Components/FooterFixed.js';


function Gites(villaArr) {

  return (
    <>
      <div className='film'>
          <h1 id='titre-video'>
              <Typewriter
                options={{
                strings:['Les petites villas Camarguaises', 'Vivez une expérience unique'],
                autoStart: true,
                loop: true,
                }}
              />
            </h1>
            <div className='tiret-hp'></div>
            <div className='background-video'>
              <video className='desktop' autoPlay loop muted>
                <source src={VillaVideo} type="video/mp4" />
              </video>
              <video className='mobile' autoPlay loop muted>
                <source src={VillaVideo} type="video/mp4" />
              </video>
            </div>
        </div> 

        {/* INTRO */}
        <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
              <h2>Réservez dans l'un de nos gîtes ou chambres</h2>
              <h3>au milieu de la nature camarguaise</h3>
          <div className='center'>
          <div className='grid3-pills'>
                <div className='grid3-pills-item'>
                  <img src={pleinpied} alt="icône de villa"/>
                  <p>villa plein-pied</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={jardin} alt="icône de clôture de jardin"/>
                  <p>terrasses et jardins</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={wifi} alt="icône de la planète Terre"/>
                  <p>TV, Radio & WIFI</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={linge} alt="icône d'une pile de linge"/>
                  <p>linges de lits & serviettes (frais supplémentaires)</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={animaux} alt="icône de chiens et chats"/>
                  <p>animaux domestiques autorisés</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={doucheprive} alt="icône de douche à l'italienne"/>
                  <p>salle de bain privative</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={clim} alt="icône de climatisation"/>
                  <p>climatisation</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={parking} alt="icône du sigle parking"/>
                  <p>parking</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={plaques} alt="icône de plaques de cuisson"/>
                  <p>plaque de cuisson</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={four} alt="icône de four"/>
                  <p>four</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={lavelinge} alt="icône de lave-linge"/>
                  <p>lave-linge</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={lavevaisselle} alt="icône de lave-vaisselle"/>
                  <p>lave-vaisselle</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={microondes} alt="icône de micro-ondes"/>
                  <p>micro-ondes</p>
                </div>
                <div className='grid3-pills-item'>
                  <img src={menage} alt="icône de produits ménagers"/>
                  <p>services de ménage au départ en supplément</p>
                </div>
              </div>
          </div>
        </div>
      </div>


      {/* VILLA 1 */}
      <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <h2>Villa La Saladelle</h2>
                <h3 style={{color:'#81B795'}}>vivez une expérience unique</h3>
            {/* GRILLE DE 4 */}
            <div style={{marginTop:'10%'}} className='grid4'>
            <div className='grid4-description'>
              <p className='emphase'>Nichée au cœur d'une paisible campagne,</p>
              <p>cette villa offre une évasion chaleureuse et intime, où le charme rustique rencontre le confort moderne.</p>
              <div className='grid4-pills'>
                        <div className='grid4-pills-item'>
                          <img src={nb} alt="icône de personnes"/>
                          <p>6 personnes</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={fermee} alt="icône de pièce fermée"/>
                          <p>cuisine séparée</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={sdb} alt="icône de baignoire"/>
                          <p>salle d'eau</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={salon} alt="icône de fauteuil avec lampe"/>
                          <p>salon</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={canape} alt="icône de canapé"/>
                          <p>1 clic-clac</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={chambres} alt="icône de lit avec table de chevet"/>
                          <p>2 chambres</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={simple} alt="icône de lit simple"/>
                          <p>2 lits simples</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={double} alt="icône de lit double"/>
                          <p>1 lit double</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={maison} alt="icône de maison"/>
                          <p>superficie : 63 m²</p>
                        </div>
              </div>
          </div>
            <img src={saladelle3} className='grid4-img' alt="la villa Saladelle vue de l'extérieur avec sa terrasse"/>
            <img src={saladelle1} className='grid4-img' alt="le salon de la villa Saladelle"/>
            <div className='grid4-img-col2'>
               <img src={saladelle4} className='grid4-img' alt="la chambre avec deux lits de la villa Saladelle"/>
               <img src={saladelle5} className='grid4-img' alt="la chambre avec deux lits de la villa Saladelle"/>
            </div>
            
        </div>
      </div>
      </div>

       {/* VILLA 2 */}
       <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <h2>Villa Les Jonquilles</h2>
          <h3 style={{color:'#81B795'}}>vivez une expérience unique</h3>
            {/* GRILLE DE 4 */}
            <div style={{marginTop:'10%'}} className='grid4'>
            <div className='grid4-description'>
              <p className='emphase'>Nichée au cœur d'une paisible campagne,</p>
              <p>cette villa offre une évasion chaleureuse et intime, où le charme rustique rencontre le confort moderne.</p>              
            <div className='grid4-pills'>
                        <div className='grid4-pills-item'>
                          <img src={nb} alt="icône de personnes"/>
                          <p>4 personnes</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={ouverte} alt="icône d'une cuisine ouverte"/>
                          <p>cuisine ouverte</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={sdb} alt="icône d'une baignoire"/>
                          <p>salle d'eau</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={salon} alt="icône d'un fauteuil avec une lampe sur pied"/>
                          <p>salon</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={chambres} alt="icône d'un lit avec sa table de chevet"/>
                          <p>2 chambres</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={simple} alt="icône d'un lit simple"/>
                          <p>2 lits simples</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={double} alt="icône d'un lit double"/>
                          <p>1 lit double</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={maison} alt="icône de maison"/>
                          <p>superficie : 47 m²</p>
                        </div>
              </div>
          </div>
            <img src={jonquille1} className='grid4-img' alt="la villa Jonquille vue de l'extérieur"/>
            <img src={jonquille2} className='grid4-img' alt="la cuisine ouverte donnant sur le salon/salle à manger"/>
            <div className='grid4-img-col2'>
              <img src={jonquille4} className='grid4-img' alt="la deuxième chambre avec deux couchages"/>
               <img src={jonquille3} className='grid4-img' alt="la première chambre avec un lit simple"/>
            </div>
        </div>
      </div>
      </div>

       {/* VILLA 3 */}
       <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <h2>Villa Les Asphodèles</h2>
                <h3 style={{color:'#81B795'}}>vivez une expérience unique</h3>
            {/* GRILLE DE 4 */}
            <div style={{marginTop:'10%'}} className='grid4'>
            <div className='grid4-description'>
              <p className='emphase'>Nichée au cœur d'une paisible campagne,</p>
              <p>cette villa offre une évasion chaleureuse et intime, où le charme rustique rencontre le confort moderne.</p>              <div className='grid4-pills'>
                        <div className='grid4-pills-item'>
                          <img src={nb} alt="icône de personnes"/>
                          <p>6 personnes</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={fermee} alt="icône de pièce fermée"/>
                          <p>cuisine séparée</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={sdb} alt="icône d'une baignoire"/>
                          <p>salle d'eau</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={salon} alt="icône d'un fauteuil et d'une lampe sur pied"/>
                          <p>salon</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={canape} alt="icône d'un canapé"/>
                          <p>1 clic-clac</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={chambres} alt="icône d'un lit et de sa table de chevet"/>
                          <p>2 chambres</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={simple} alt="icône d'un lit simple"/>
                          <p>2 lits simples</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={double} alt="icône d'un lit double"/>
                          <p>1 lit double</p>
                        </div>
                        <div className='grid4-pills-item'>
                          <img src={maison} alt="icône de maison"/>
                          <p>superficie : 53 m²</p>
                        </div>
              </div>
          </div>
            <img src={asphodele1} className='grid4-img' alt="la villa Asphodèle vue de l'extérieur"/>
            <img src={asphodele2} className='grid4-img' alt="le salon de la villa Asphodèle"/>
            <div className='grid4-img-col2'>
               <img src={asphodele3} className='grid4-img' alt="la première chambre de la villa Asphodèle avec un couchage simple"/>
               <img src={asphodele4} className='grid4-img' alt="la deuxième chambre de la villa Asphodèle avec un couchage double"/>
            </div>
        </div>
      </div>
      </div>
         

       {/* TICKET DE CONTACT */}
       <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
            <h2 style={{marginTop:0}}>Réservez un gîte</h2>
            <h3 style={{color:'#81B795'}}> 
              & ressourcez-vous le temps
              <Typewriter 
                options={{
                  strings:['  d\'un week-end', ' d\'une semaine'],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h3>
          <div style={{marginTop:'3%'}} className='formulaire'>
            <FormGi/>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className='section' style={{backgroundColor:'#81B795', paddingTop:0}}>
        <div style={{position:'relative'}}>
          <Footer />
          <FooterFixed/>
        </div>
      </div>


    </>
  );
}

export default Gites;
