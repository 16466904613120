import React from 'react'
import '../../App.scss'
import Phone from '../Elements/icons/footer/phone.png';
import screen from '../Elements/icons/footer/phone-screenshot.png';
import screen2 from '../Elements/icons/footer/phone-screenshot2.png';

function Footer() {

  const call = ()=>{
    window.location.href = 'tel://' + '0616704192';
  }
  
  return (
    <div className='footer-fixed-grid'>
      <div className='footer-fixed-grid-item'>
      <img src={Phone} alt="icône de téléphone"/> <img src={screen2} onClick={call} id='phone-screen' alt="lien clicable vers le numéro"/>
      </div>
      <div className='footer-fixed-grid-item'>
      <img src={Phone} alt="icône de téléphone"/> <img src={screen} onClick={call} id='phone-screen' alt="lien clicable vers le numéro"/> 
      </div>
      <div className='footer-fixed-grid-item'>
        <p>© site réalisé par ● cible publicité montpellier</p>
      </div>
    </div>
  )
}

export default Footer